import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExternalFeedbackComponent } from 'app/external/feedback/feedback.component';
import { MasterPageComponent } from '../general/master-page/master-page.component';

import { SellerCockpitComponent } from './cockpit/cockpit.component';
import { SellerConfigurarCondicoesFornecimentoComponent } from './configurar-condicoes-fornecimento/configurar-condicoes-fornecimento.component';
import { SellerDashboardComponent } from './dashboard/dashboard.component';
import { SellerDadosCotacaoNotasFiscaisComponent } from './_componentes/dados-cotacao/_componentes/notas-fiscais/notas-fiscais.component';
import { SellerDocumentacaoComponent } from './documentacao/documentacao.component';
import { SellerPedidosComponent } from './pedidos/pedidos.component';
import { SellerVisaoGeralConfirmarComponent } from './confirmar/confirmar.component';
import { SellerVisaoGeralDetalheComponent } from './detalhe/detalhe.component';
import { SellerVisaoGeralResponderComponent } from './responder/responder.component';

import { SellerDadosCotacaoNotasFiscaisResolve } from './_componentes/dados-cotacao/_services/notas-fiscais.resolve';
import { getScreenSizeIsMobile } from 'app/_utils/general-utils';

const sellerRouterConfig: Routes = !getScreenSizeIsMobile() ? [
    {
        path: '', component: MasterPageComponent,
        children: [
            { path: 'visao-geral', component: SellerDashboardComponent },
            { path: 'visao-geral/confirmar-pedido/:id', component: SellerVisaoGeralConfirmarComponent },
            { path: 'visao-geral/detalhe/:id', component: SellerVisaoGeralDetalheComponent },
            { path: 'visao-geral/responder/:id', component: SellerVisaoGeralResponderComponent },
            { path: 'cockpit', component: SellerCockpitComponent },
            { path: 'configurar-condicoes-fornecimento', component: SellerConfigurarCondicoesFornecimentoComponent },
            { path: 'documentacao', component: SellerDocumentacaoComponent },
            {
                path: 'notas-fiscais/:id',
                component: SellerDadosCotacaoNotasFiscaisComponent,
                resolve: { pedido: SellerDadosCotacaoNotasFiscaisResolve }
            },
            { path: 'pedidos', component: SellerPedidosComponent }
        ]
    }
] : [
    {
        path: '', component: MasterPageComponent,
        children: [
            { path: 'visao-geral', component: SellerDashboardComponent },
            { path: 'visao-geral/responder/:id', component: SellerVisaoGeralResponderComponent },
            { path: 'visao-geral/responder/feedback/:tipo', component: ExternalFeedbackComponent },
        ]
    }
];

@NgModule({
    imports:
        [
            RouterModule.forChild(sellerRouterConfig)
        ],
    exports: [RouterModule]
})
export class SellerRoutingModule { }

